import {
	TABLET_M_BP,
	MOBILE_L_BP,
	MOBILE_LM_BP,
	MOBILE_SM_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import styled from "styled-components";

export const Wrapper = styled.div`
	margin: 80px 0;
	color: var(--primary-neutral-nightshade-800);
	text-align: center;

	.table-description {
		margin-top: 1.5rem;
		& * {
			font-family: inherit;
			font-size: inherit;
			line-height: inherit;
			letter-spacing: inherit;
		}
		& a {
			text-decoration: none;
			font-weight: var(--fw-haffer-xh-2);
			color: var(--secondary-purple-300);
		}
		& a:hover {
			text-decoration: underline;
		}
	}

	.overflow-container {
		overflow-x: auto;
	}
`;

export const TableWrapper = styled.table`
	margin: 3rem auto 0 auto;
	border-spacing: 0;
	text-align: left;
	max-width: 100%;
	overflow-x: auto;

	.highlight {
		background: ${({ highlightColor }) =>
			highlightColor ? highlightColor : "var(--primary-green-100)"};
	}

	.border-top {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
	.border-bottom {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.padding-bottom {
		padding-bottom: 2.5rem;
	}

	th,
	tr:not(:last-child) > td {
		border-bottom: 1px solid var(--secondary-gray-medium);
	}
	.border-right {
		border-right: 1px solid var(--secondary-gray-medium);
	}

	th,
	td {
		max-width: 250px;
		min-width: 150px;
		padding: 1rem;
		vertical-align: top;

		.gray-text {
			color: var(--secondary-gray-dark);
		}
	}

	th {
		padding-top: 2.5rem;
	}
`;

export const TableWrapperInMobile = styled.table`
	margin: 3rem auto 0px;

	.table-title {
		background: ${({ compactTableHeaderColor }) =>
			compactTableHeaderColor ? compactTableHeaderColor : "#edefee"};
		min-width: 40%;
		font-size: 0.938rem;
		padding: 0.7rem;

		@media only screen and (max-width: ${MOBILE_SM_BP}px) {
			font-size: 0.625rem;
		}

		@media only screen and (min-width: ${MOBILE_SM_BP}px) and (max-width: ${MOBILE_LM_BP}px) {
			font-size: 0.688rem;
		}

		@media only screen and (min-width: ${MOBILE_LM_BP}px) and (max-width: ${MOBILE_L_BP}px) {
			font-size: 0.813rem;
		}
	}

	.highlighted-text {
		color: var(--primary-forest-400);
	}

	.table-content {
		margin-top: 10px;
	}

	.table-row {
		margin-bottom: 16px;
	}

	.table-items {
		display: flex;
		margin-top: 6px;
	}

	.table-item {
		margin: 0px 40px;
		width: 96px;
		padding-right: 2px;
		border-right: 1px solid #d7d7dc;
		font-size: 0.938rem;

		@media only screen and (max-width: ${MOBILE_SM_BP}px) {
			font-size: 0.625rem;
		}

		@media only screen and (min-width: ${MOBILE_SM_BP}px) and (max-width: ${MOBILE_LM_BP}px) {
			font-size: 0.688rem;
			width: 82px;
		}

		@media only screen and (min-width: ${MOBILE_LM_BP}px) and (max-width: ${MOBILE_L_BP}px) {
			font-size: 0.813rem;
		}
	}

	.table-item:last-child {
		border-right: none;
	}
`;

export const FooterSection = styled.div`
	display: flex;
	column-gap: 1rem;
	row-gap: 0.5rem;
	justify-content: center;
	color: var(--secondary-gray-dark);

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		flex-direction: column;
	}

	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		align-items: flex-start;
		text-align: left;
	}
`;
