import React from "react";
import { useMediaQuery } from "@react-hook/media-query";
import {
	Grid,
	Col,
	Text,
	Separator,
} from "@website-builder/ui/shared/elements";
import {
	Wrapper,
	TableWrapper,
	FooterSection,
	TableWrapperInMobile,
} from "./styles";
import { formatColor } from "@website-builder/utilities/utils/DesignUtils.js";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";

const ComparisonTable = (props) => {
	const {
		header,
		description,
		footer,
		table,
		highlightedColumn,
		highlightColor,
		isDarkBg = false,
		showCompactTableInMobile,
		compactTableHeaderColor,
	} = props;
	const isMobile = useMediaQuery(`(max-width: ${TABLET_M_BP}px)`);

	// The description, when set to empty on Storyblok, sometimes comes to us
	// as an empty tags, such as <p></p> or <h1></h1>. We don't want to render
	// this, so we disable it based on a flag.
	const emptyTagRegex = /^<(\w+)>\s*<\/\1>$/;
	const hasEmptyTag = emptyTagRegex.test(description);

	const getClasses = (element, index, rowIndex) => {
		let classList = "";
		if (element === "th") {
			if (highlightedColumn && highlightedColumn - 1 === index) {
				classList += "border-top ";
			}
			if (
				highlightedColumn - 1 !== index &&
				highlightedColumn - 2 !== index &&
				table.thead.length - 1 !== index
			) {
				classList += "border-right";
			}
			return classList || null;
		}

		if (element === "td") {
			if (
				highlightedColumn &&
				table.tbody.length - 1 === rowIndex &&
				highlightedColumn - 1 === index
			) {
				classList += "border-bottom ";
			}
			if (
				highlightedColumn - 1 !== index &&
				highlightedColumn - 2 !== index &&
				table.thead.length - 1 !== index
			) {
				classList += "border-right ";
			}
			if (table.tbody.length - 1 === rowIndex) {
				classList += "padding-bottom";
			}
			return classList || null;
		}
	};

	const getTableHeader = (item, index) => {
		return (
			<th scope="col" className={getClasses("th", index)}>
				<Text dark={isDarkBg} variant="paragraph_L_bold">
					{item.value}
				</Text>
			</th>
		);
	};

	const getTableRowData = (row, rowIndex) => {
		return row.body.map((item, index) => {
			return (
				<td
					key={index}
					className={getClasses("td", index, rowIndex)}
					scope={index === 0 ? "row" : null}
				>
					<Text
						variant={index === 0 ? "paragraph_M" : "paragraph_L"}
						className={index === 0 && !isDarkBg ? "gray-text" : ""}
						dark={isDarkBg}
					>
						{item.value}
					</Text>
				</td>
			);
		});
	};

	const getTableRowDataForMobile = (row) => {
		return (
			<>
				<Text className="table-title"> {row.body[0].value} </Text>
				<tr>
					{row.body.map(
						(item, index) =>
							index !== 0 && (
								<td key={index} className="table-item">
									{item.value}
								</td>
							),
					)}
				</tr>
			</>
		);
	};

	return (
		<Wrapper isDarkBackground={isDarkBg} className="comparison-table">
			<Grid>
				{header ? (
					<Col start={3} span={8} mdStart={0} mdSpan={6}>
						<Text dark={isDarkBg} variant="headline_3" tag="h2">
							{header}
						</Text>
					</Col>
				) : null}

				{!hasEmptyTag ? (
					<Col start={4} span={6} mdStart={0} mdSpan={6}>
						<Text
							variant="paragraph_M"
							tag="div"
							className="table-description"
							dark={isDarkBg}
							dangerouslySetInnerHTML={{ __html: description }}
						/>
					</Col>
				) : null}

				{table?.thead || table?.tbody ? (
					<Col
						start={2}
						span={10}
						mdStart={0}
						mdSpan={6}
						className="overflow-container"
					>
						{(!showCompactTableInMobile || !isMobile) && (
							<TableWrapper
								highlightColor={!!highlightColor && formatColor(highlightColor)}
							>
								{table?.thead ? (
									<>
										<colgroup>
											{table.thead.map((x, index) => {
												return (
													<col
														key={index}
														className={
															highlightedColumn &&
															highlightedColumn - 1 === index
																? "highlight"
																: null
														}
													/>
												);
											})}
										</colgroup>
										<thead>
											<tr>
												{table.thead.map((item, index) => {
													return (
														<React.Fragment key={index}>
															{getTableHeader(item, index)}
														</React.Fragment>
													);
												})}
											</tr>
										</thead>
									</>
								) : null}
								{table.tbody ? (
									<tbody>
										{table.tbody.map((row, rowIndex) => {
											return (
												<tr key={rowIndex}>{getTableRowData(row, rowIndex)}</tr>
											);
										})}
									</tbody>
								) : null}
							</TableWrapper>
						)}

						{showCompactTableInMobile && isMobile && (
							<>
								<TableWrapperInMobile
									compactTableHeaderColor={
										!!compactTableHeaderColor &&
										formatColor(compactTableHeaderColor)
									}
								>
									<br />
									<div>
										{table.thead.map((row, rowIndex) => {
											return (
												rowIndex !== 0 && (
													<td
														key={rowIndex}
														className={`table-item ${
															Number(highlightedColumn) === rowIndex + 1 &&
															"highlighted-text"
														}`}
													>
														{row.value}
													</td>
												)
											);
										})}
									</div>

									<div className="table-content">
										{table.tbody.map((row, rowIndex) => {
											return (
												<div key={rowIndex} className="table-row">
													{getTableRowDataForMobile(row, rowIndex)}
												</div>
											);
										})}
									</div>

									<br />
								</TableWrapperInMobile>
							</>
						)}
					</Col>
				) : null}

				{footer && footer.length ? (
					<Col start={2} span={10} mdStart={0} mdSpan={6}>
						<FooterSection>
							{footer.map((item, index) => {
								return (
									<React.Fragment key={index}>
										<Text
											variant="paragraph_S"
											tag="div"
											dangerouslySetInnerHTML={{ __html: item }}
										/>
										{index !== footer.length - 1 && !isMobile ? (
											<Separator vertical />
										) : null}
									</React.Fragment>
								);
							})}
						</FooterSection>
					</Col>
				) : null}
			</Grid>
		</Wrapper>
	);
};

export default ComparisonTable;
